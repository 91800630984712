import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../scss/site.scss"
import "../scss/impressum.scss"

const Impressum = () => {
  return (
    <Layout>
      <SEO
        title="Impressum | freie ingenieure bau"
        description="Impressum"
        lang="de"
      />
      <div className="fib-container-text fib-imprint-styles">
        <div className="fib-sp80"></div>

        <div className="uk-width-8-12@m">
          <h1>Impressum</h1>
          <p>
            freie ingenieure bau | Dr. Belaschk + Krätschell PartGmbB
            <br />
            Elvirasteig 11
            <br />
            14163 Berlin
            <br />
            <span itemProp="telephone">
              <a className="fib-text-link" href="tel:+493098422780">
                T +49 30 98422780
              </a>
            </span>
            <br />
            <span itemProp="telephone">
              <a className="fib-text-link" href="tel:+493022185925">
                F +49 30 22185925
              </a>
            </span>
            <br />
            <Obfuscate
              className="fib-text-link"
              email="info@freie-ingenieure-bau.de"
            >
              info@freie-ingenieure-bau.de
            </Obfuscate>
          </p>
          <h2>Vertreten durch</h2>
          <p>Dr.-Ing. H. Belaschk, Dipl.-Ing. M. Krätschell</p>
          <h2>Registergericht</h2>
          <p>Amtsgericht Charlottenburg PR 1438</p>
          {/* <h2>Umsatzsteuer-Identifikationsnummer</h2>
          <p>DE???????????????</p> */}
          <h2> Berufsbezeichnung</h2>

          <p>
            Dr.-Ing. Hendrik Belaschk
            <ul className="fib-list">
              <li>
                Zertifizierter Sachverständiger für den vorbeugenden und
                gebäudetechnischen Brandschutz (DIN EN ISO/IEC 17024)
              </li>
              <li>Pflichtmitglied gemäß Architekten- und Baukammergesetz</li>
            </ul>
            Dipl.-Ing. Michael Krätschell <br />
            <ul className="fib-list">
              <li>
                Öffentlich bestellter und vereidigter Sachverständiger für
                Schäden an Gebäuden und Wärme- sowie Feuchteschutz (IHK Berlin)
              </li>
              <li>
                Von der Obersten Bauaufsicht Berlin anerkannter
                Prüfsachverständiger für energetische Gebäudeplanung
              </li>
              <li>Pflichtmitglied gemäß Architekten- und Baukammergesetz</li>
            </ul>
          </p>
          <h2>Zuständige Kammern</h2>
          <p>
            <ul className="fib-list">
              <li>Baukammer Berlin, Heerstraße 18/20, 14052 Berlin</li>
              <li>IHK Berlin, Fasanenstraße 85, 10623 Berlin</li>
            </ul>
          </p>
          <h2>Berufsrechtliche Regelungen</h2>
          <p>
            <ul className="fib-list">
              <li>Berliner Architekten- und Baukammergesetz</li>
              <li>
                Berufsordnung der Baukammer Berlin Honorarordnung für
                Architekten und Ingenieure (HOAI)
              </li>
              <li>
                Ingenieurgesetz Sachverständigenordnungen (IHK Berlin, Baukammer
                Berlin, Brandenburgische Ingenieurkammer u.a.)
              </li>
              <li>
                Weitere Regelungen unter&nbsp;
                <a
                  className="fib-text-link"
                  href="http://www.baukammer-berlin.de/recht/gesetze-und-verordnungen"
                >
                  baukammer-berlin.de/recht/gesetze-und-verordnungen
                </a>
              </li>
            </ul>
          </p>
          <h2>Berufshaftpflichtversicherung</h2>
          <p>HDI Versicherung AG, HDI-Platz 1, 30659 Hannover</p>
          {/* <h2>Geltungsbereich des Versicherungsschutzes</h2>
          <p>????</p> */}
          <h2>Information gemäß § 36 VSBG</h2>
          <p>
            Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
            alternativeStreitbeilegung in Verbrauchersachen) erklärt der
            Betreiber dieser Website: Wir sind bereit, an
            Streitbeilegungsverfahren bei der folgenden
            Verbraucherschlichtungsstelle teilzunehmen: Schlichtungsausschuss
            der Baukammer Berlin
            <br />
            <a
              className="fib-text-link"
              href="https://www.baukammerberlin.de/service/schlichtung"
            >
              https://baukammerberlin.de/service/schlichtung
            </a>
          </p>
          <h2>Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV</h2>
          <p>Hendrik Belaschk (Anschrift wie oben)</p>
          <h2>Datenschutzerklärung</h2>
          <p>
            <a
              className="fib-text-link"
              href="https://freie-ingenieure-bau.de/datenschutz"
            >
              https://freie-ingenieure-bau.de/datenschutz
            </a>
          </p>

          <hr />

          <h2>Urheberrechtliche Hinweise</h2>
          <p>
            Das Konzept und Design der Webseite, die ver­wendeten Grafiken,
            sowie sämtliche Inhalte sind urheber­rechtlich geschützt.
          </p>
          <h2>Konzept und Design</h2>
          <p>
            may+steinmüller Design­bureau
            <br />
            <a className="fib-text-link" href="https://maystein.de">
              https://maystein.de
            </a>
          </p>

          <h2>Fotografie</h2>
          <p>
            Svenja Paulsen
            <br />
            <a className="fib-text-link" href="https://svenjapaulsen.de">
              https://svenjapaulsen.de
            </a>
          </p>

          <hr />

          <p>
            Das Impressum wurde mit dem Impressums-Generator der activeMind AG
            erstellt.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Impressum
